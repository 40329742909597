import { createIcon } from '@chakra-ui/react'

export const Logout = createIcon({
  displayName: 'Logout',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M9.375 16.875C9.375 17.0407 9.30915 17.1997 9.19194 17.3169C9.07473 17.4341 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4341 3.30806 17.3169C3.19085 17.1997 3.125 17.0407 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.433C9.30915 16.5502 9.375 16.7092 9.375 16.875ZM17.9422 9.5578L14.8172 6.43281C14.6999 6.31553 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31553 13.9328 6.43281C13.8155 6.55008 13.7497 6.70914 13.7497 6.87499C13.7497 7.04085 13.8155 7.19991 13.9328 7.31718L15.9914 9.37499H8.75C8.58424 9.37499 8.42527 9.44084 8.30806 9.55805C8.19085 9.67526 8.125 9.83423 8.125 9.99999C8.125 10.1657 8.19085 10.3247 8.30806 10.4419C8.42527 10.5591 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2908 13.8155 13.4499 13.9328 13.5672C14.0501 13.6844 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6844 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0778 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 9.99999C18.1255 9.91785 18.1093 9.83652 18.0778 9.76065C18.0464 9.68478 18.0003 9.61585 17.9422 9.5578Z"
      fill="currentColor"
    />
  ),
})
